/** @jsx jsx */

import ReactMarkdown from 'react-markdown'
import { Squidex_Page } from 'src/types/gatsby-types'
import { jsx } from '@emotion/react'
import { subtitleText } from 'src/components/common/Typography'

type Props = {
  page: Squidex_Page
}

export const ContentPageIntro: React.FC<Props> = ({ page }) => {
  return (
    <div css={subtitleText}>
      <ReactMarkdown>{page.flatData.content || ''}</ReactMarkdown>
    </div>
  )
}
