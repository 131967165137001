import { PageProps, graphql } from 'gatsby'
import {
  SquidexFindPageContentArgs,
  Squidex_Page,
} from 'src/types/gatsby-types'

import { ContentPage } from 'src/components/ContentPage/ContentPage'
import { Layout } from 'src/components/Layout/Layout'
import React from 'react'

export type PageData = {
  squidex: {
    findPageContent?: Squidex_Page
  }
}

export type PageContext = SquidexFindPageContentArgs

const PageTemplate: React.FC<PageProps<PageData, PageContext>> = ({
  data,
  pageContext,
}) => {
  const page = data?.squidex?.findPageContent

  if (!page) {
    throw new Error(`Page data ${pageContext.id} not found`)
  }

  return (
    <Layout title={`${page.flatData.title} | RabbitPeepers` || 'Content page'}>
      <ContentPage page={page} />
    </Layout>
  )
}

export const query = graphql`
  query page($id: String!) {
    squidex {
      findPageContent(id: $id) {
        id
        flatData {
          content
          title
          blocks {
            id
            flatData {
              title
              subtitle
              motto
              linkText
              linkHref
              isButtonPrimary
              description
              contentCssClass
              buttonText
              buttonLink
              illustration {
                ...IllustrationImage
              }
            }
          }
        }
      }
    }
  }
`

export default PageTemplate
