/** @jsx jsx */
import { css, jsx } from '@emotion/react'

import { ButtonLink } from 'src/components/Button/Button'
import { Link } from 'gatsby'
import { Squidex_ContentBlockFlatDataDto } from 'src/types/gatsby-types'
import { regularLink } from 'src/components/common/Typography'

const wrapper = css`
  margin: 16px 0 0;
  display: flex;
  align-items: center;
`

const linkWrapper = css`
  padding: 0;
`

type Props = Pick<
  Squidex_ContentBlockFlatDataDto,
  'buttonLink' | 'buttonText' | 'linkHref' | 'linkText' | 'isButtonPrimary'
>

export const WhatDoWeDoButtons: React.FC<Props> = (data) => {
  return (
    <div css={wrapper}>
      {data.buttonText && data.buttonLink ? (
        <ButtonLink
          variant={data.isButtonPrimary ? 'contained' : 'normal'}
          to={data.buttonLink}
        >
          {data.buttonText}
        </ButtonLink>
      ) : null}
      {data.linkText && data.linkHref ? (
        <div css={linkWrapper}>
          <Link to={data.linkHref} css={regularLink}>
            {data.linkText}
          </Link>
        </div>
      ) : null}
    </div>
  )
}
