/** @jsx jsx */

import { css, jsx } from '@emotion/react'

import { Container } from 'src/components/common/Container'
import { ContentPageBlocks } from 'src/components/ContentPage/ContentPageBlocks'
import { ContentPageIntro } from 'src/components/ContentPage/ContentPageIntro'
import { ReadyToStart } from 'src/components/ReadyToStart/ReadyToStart'
import { Squidex_Page } from 'src/types/gatsby-types'
import { graphql } from 'gatsby'
import { headingSecondary } from 'src/components/common/Typography'
import { motion } from 'framer-motion'
import { mq } from 'src/utils/mq'
import { theme } from 'src/utils/theme'

const titleStyles = css`
  ${headingSecondary};
  color: ${theme.colors.primaryText}!important;

  ${mq.md} {
    margin-top: 15px;
  }
`

const wrapperStyles = css`
  padding: 0 16px;
`

type Props = {
  page: Squidex_Page
}

export const ContentPage: React.FC<Props> = ({ page }) => {
  return (
    <Container css={wrapperStyles}>
      <motion.div
        initial={{ y: 50, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        transition={{ duration: 0.4 }}
      >
        <h1 css={titleStyles}>{page.flatData.title}</h1>
        <ContentPageIntro page={page} />
      </motion.div>

      <ContentPageBlocks page={page} />
      <ReadyToStart />
    </Container>
  )
}

export const query = graphql`
  fragment IllustrationImage on Squidex_Asset {
    url
    slug
    id
    metadata(path: "description")
    imageFile {
      extension
      relativePath
      publicURL
      childImageSharp {
        gatsbyImageData(quality: 90)
      }
      svg {
        content
      }
    }
  }
`
