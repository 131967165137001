/** @jsx jsx */

import { css, jsx } from '@emotion/react'

import { ContentPageBlockItem } from 'src/components/ContentPage/ContentPageBlockItem'
import React from 'react'
import { Squidex_Page } from 'src/types/gatsby-types'
import { mq } from 'src/utils/mq'

type Props = {
  page: Squidex_Page
}

const wrapperStyles = css`
  margin: 48px 0;

  ${mq.lg} {
    margin: 64px 0;
  }
`

export const ContentPageBlocks: React.FC<Props> = ({ page }) => {
  const { blocks } = page.flatData

  if (!blocks?.length) {
    return null
  }

  return (
    <main css={wrapperStyles}>
      {blocks.map((i, index) => <ContentPageBlockItem block={i} key={i.id} imageRight={index % 2 !== 0} />)}
    </main>
  )
}
